// extracted by mini-css-extract-plugin
export var animatedBtn = "homeSingleFeatured-module--animated-btn--gy02w";
export var bannerHeader = "homeSingleFeatured-module--banner-header--Nhv92";
export var btn = "homeSingleFeatured-module--btn--nRvCj";
export var container = "homeSingleFeatured-module--container--zxNSC";
export var dark = "homeSingleFeatured-module--dark--Oj13F";
export var detailsTextPortable = "homeSingleFeatured-module--detailsTextPortable--rXIJo";
export var deviceMockupsWrapper = "homeSingleFeatured-module--device-mockups-wrapper--AUkhi";
export var headerFeatures = "homeSingleFeatured-module--header-features--WMTZ2";
export var intro = "homeSingleFeatured-module--intro--CY+v6";
export var lineIntro = "homeSingleFeatured-module--line-intro--gNIud";
export var link = "homeSingleFeatured-module--link--+vbrN";
export var marqueText = "homeSingleFeatured-module--marque-text--K5jR9";
export var menuBackground = "homeSingleFeatured-module--menu-background--iRjXP";
export var menuLink = "homeSingleFeatured-module--menu-link--OVPad";
export var overlay = "homeSingleFeatured-module--overlay--4pKYF";
export var pageBtn = "homeSingleFeatured-module--page-btn--aj6W5";
export var projectCard = "homeSingleFeatured-module--projectCard---dqrX";
export var projectCardHeader = "homeSingleFeatured-module--projectCardHeader--+eoN1";
export var projectCardImage = "homeSingleFeatured-module--projectCardImage--l3bcK";
export var projectCardImageHover = "homeSingleFeatured-module--projectCardImageHover--GqpKt";
export var projectCardWrapper = "homeSingleFeatured-module--projectCardWrapper--XyPkA";
export var question = "homeSingleFeatured-module--question--UjH2W";
export var sectionFeatured = "homeSingleFeatured-module--sectionFeatured--gDLR0";
export var sectionFeaturedWrapper = "homeSingleFeatured-module--sectionFeaturedWrapper--NN1TD";
export var swiper = "homeSingleFeatured-module--swiper--WfsGf";
export var swiperImage = "homeSingleFeatured-module--swiperImage--HP2VC";
export var swiperSlide = "homeSingleFeatured-module--swiperSlide--WOV+W";
export var swiperSlideItem = "homeSingleFeatured-module--swiperSlideItem--o1bac";
export var testimonial = "homeSingleFeatured-module--testimonial--zCNF-";
export var textColor = "homeSingleFeatured-module--text-color--HXP8a";
export var textUppercase = "homeSingleFeatured-module--text-uppercase--eaoJ3";