// extracted by mini-css-extract-plugin
export var animatedBtn = "homeSingleFocus-module--animated-btn--ehWKQ";
export var bannerHeader = "homeSingleFocus-module--banner-header--vp0pA";
export var btn = "homeSingleFocus-module--btn--sllbC";
export var container = "homeSingleFocus-module--container--SblFP";
export var dark = "homeSingleFocus-module--dark--3TE9B";
export var detailsTextPortable = "homeSingleFocus-module--detailsTextPortable---kB2N";
export var deviceMockupsWrapper = "homeSingleFocus-module--device-mockups-wrapper--ey6C+";
export var focusArea = "homeSingleFocus-module--focusArea--B2fdB";
export var focusAreaMedia = "homeSingleFocus-module--focusAreaMedia--3YdGO";
export var focusAreasWrapper = "homeSingleFocus-module--focusAreasWrapper--mqfAb";
export var headerFeatures = "homeSingleFocus-module--header-features--tmcst";
export var intro = "homeSingleFocus-module--intro--4sHOV";
export var lineIntro = "homeSingleFocus-module--line-intro--GCzaB";
export var link = "homeSingleFocus-module--link--cIbAh";
export var marqueText = "homeSingleFocus-module--marque-text---7Zjq";
export var menuBackground = "homeSingleFocus-module--menu-background--F8E4d";
export var menuLink = "homeSingleFocus-module--menu-link--YRjQV";
export var overlay = "homeSingleFocus-module--overlay--sTdRB";
export var pageBtn = "homeSingleFocus-module--page-btn--Yodip";
export var question = "homeSingleFocus-module--question--8YUJ1";
export var sectionFocus = "homeSingleFocus-module--sectionFocus--M0q2Y";
export var testimonial = "homeSingleFocus-module--testimonial--qa6JJ";
export var textColor = "homeSingleFocus-module--text-color--pbGJ8";
export var textUppercase = "homeSingleFocus-module--text-uppercase--N0feN";