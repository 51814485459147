// extracted by mini-css-extract-plugin
export var animatedBtn = "homeSingleBanner-module--animated-btn--iXYlb";
export var bannerGallery = "homeSingleBanner-module--bannerGallery--i3ceA";
export var bannerGalleryItem = "homeSingleBanner-module--bannerGalleryItem--eCI1Y";
export var bannerGalleryWrapper = "homeSingleBanner-module--bannerGalleryWrapper--PaF3l";
export var bannerHeader = "homeSingleBanner-module--banner-header--+5D76";
export var btn = "homeSingleBanner-module--btn--ATI1P";
export var container = "homeSingleBanner-module--container--REbKe";
export var dark = "homeSingleBanner-module--dark--kXwqb";
export var detailsTextPortable = "homeSingleBanner-module--detailsTextPortable--+JtLL";
export var deviceMockupsWrapper = "homeSingleBanner-module--device-mockups-wrapper--iHyuh";
export var galleryImage = "homeSingleBanner-module--galleryImage--Rypra";
export var headerFeatures = "homeSingleBanner-module--header-features--Y2ZgR";
export var intro = "homeSingleBanner-module--intro--5UpFM";
export var introBtn = "homeSingleBanner-module--introBtn--wrBWm";
export var introContent = "homeSingleBanner-module--introContent--dwNi9";
export var lineIntro = "homeSingleBanner-module--line-intro--0b0b8";
export var link = "homeSingleBanner-module--link--uhpGO";
export var marqueText = "homeSingleBanner-module--marque-text--U30M2";
export var menuBackground = "homeSingleBanner-module--menu-background--iQ8xT";
export var menuLink = "homeSingleBanner-module--menu-link--QH3fh";
export var overlay = "homeSingleBanner-module--overlay--dT+8W";
export var pageBtn = "homeSingleBanner-module--page-btn--+WrRK";
export var question = "homeSingleBanner-module--question--LXB+D";
export var testimonial = "homeSingleBanner-module--testimonial--F-Bd9";
export var textColor = "homeSingleBanner-module--text-color--txC1K";
export var textUppercase = "homeSingleBanner-module--text-uppercase--h-FHy";