// extracted by mini-css-extract-plugin
export var animatedBtn = "homeSingleGallery-module--animated-btn--AX3In";
export var bannerHeader = "homeSingleGallery-module--banner-header--T7tdx";
export var btn = "homeSingleGallery-module--btn--KrzVm";
export var container = "homeSingleGallery-module--container--3+jFE";
export var dark = "homeSingleGallery-module--dark--kDbJQ";
export var detailsTextPortable = "homeSingleGallery-module--detailsTextPortable--8lycG";
export var deviceMockupsWrapper = "homeSingleGallery-module--device-mockups-wrapper--iTFWw";
export var headerFeatures = "homeSingleGallery-module--header-features--4wSjK";
export var image = "homeSingleGallery-module--image--3h02Q";
export var intro = "homeSingleGallery-module--intro--yTW4l";
export var lineIntro = "homeSingleGallery-module--line-intro--3qtrH";
export var link = "homeSingleGallery-module--link--h+ZR1";
export var marqueText = "homeSingleGallery-module--marque-text--kZoFD";
export var menuBackground = "homeSingleGallery-module--menu-background--arG2W";
export var menuLink = "homeSingleGallery-module--menu-link--SMvAA";
export var overlay = "homeSingleGallery-module--overlay--bGJYD";
export var pageBtn = "homeSingleGallery-module--page-btn--0RzWy";
export var projectMockup = "homeSingleGallery-module--projectMockup--uZSKI";
export var question = "homeSingleGallery-module--question--fVsYV";
export var sectionGallery = "homeSingleGallery-module--sectionGallery--+wIqm";
export var testimonial = "homeSingleGallery-module--testimonial--VKH5s";
export var textColor = "homeSingleGallery-module--text-color--830g8";
export var textUppercase = "homeSingleGallery-module--text-uppercase--p6Wdj";