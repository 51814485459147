// extracted by mini-css-extract-plugin
export var animatedBtn = "homeSingleServices-module--animated-btn--mzcUV";
export var bannerHeader = "homeSingleServices-module--banner-header--HulQ7";
export var btn = "homeSingleServices-module--btn--tyZ42";
export var container = "homeSingleServices-module--container--4h-EI";
export var dark = "homeSingleServices-module--dark--fQyku";
export var detailsTextPortable = "homeSingleServices-module--detailsTextPortable--tUckU";
export var deviceMockupsWrapper = "homeSingleServices-module--device-mockups-wrapper--LgS+s";
export var headerFeatures = "homeSingleServices-module--header-features--TGLWx";
export var intro = "homeSingleServices-module--intro--P32Cv";
export var lineIntro = "homeSingleServices-module--line-intro--hHSuR";
export var link = "homeSingleServices-module--link--YiIX3";
export var marqueText = "homeSingleServices-module--marque-text--vhbuv";
export var menuBackground = "homeSingleServices-module--menu-background--zyzN2";
export var menuLink = "homeSingleServices-module--menu-link--Qk4zp";
export var overlay = "homeSingleServices-module--overlay--gL07G";
export var pageBtn = "homeSingleServices-module--page-btn--iLntS";
export var question = "homeSingleServices-module--question--QavdK";
export var sectionServices = "homeSingleServices-module--sectionServices--ixKrO";
export var sectionServicesHeader = "homeSingleServices-module--sectionServicesHeader--3I31w";
export var serviceMarqueeCard = "homeSingleServices-module--serviceMarqueeCard--tsbRf";
export var serviceMedia = "homeSingleServices-module--serviceMedia--vHM8f";
export var serviceWrapper = "homeSingleServices-module--serviceWrapper--f3fge";
export var testimonial = "homeSingleServices-module--testimonial--jbwl9";
export var textColor = "homeSingleServices-module--text-color--anjGn";
export var textUppercase = "homeSingleServices-module--text-uppercase---l4Uw";